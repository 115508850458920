import React from 'react';
import changeOrderNum from "../../../helpers/changeOrderNum";
import {useDispatch} from "react-redux";
import {fetchCashierDeleteOrder, fetchCashierReadyOrder} from "../../../store/boardSlice";
import {useTranslation} from "react-i18next";


function CashierCard({data}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sendOrder = () => {
        dispatch(fetchCashierReadyOrder({id: data.poster_order_id}));
    }

    const deleteOrder = () => {
        dispatch(fetchCashierDeleteOrder({id: data.id}));
    }

    return (
        <div className="cashier-card">
            <div className="cashier-card__head">
                <div className="cashier-card__head__title">{t('cashier_card.title')}</div>
                <div className="cashier-card__head__number">{data.incomingOrderId && data.isCustomNumber ? data.incomingOrderId : changeOrderNum(data.poster_order_num)}</div>
            </div>
            <div className="cashier-card__line"></div>
            <div className="cashier-card__list">
                {data.data && data.data.products && Object.values(data.data.products).map((product, index) => {
                    return (
                        <div className="cashier-card__list__item" key={index}>
                            <div className="cashier-card__list__item__name">{product.name}</div>
                            <div className="cashier-card__list__item__count">x {product.count}</div>
                        </div>
                    )
                })}
            </div>
            {
                !data.ready ? (
                    <div className="cashier-card__btn" onClick={() => {
                        sendOrder()
                    }}>
                        {t('cashier_card.ready')}
                    </div>
                ) : (
                    <div className="cashier-card__btn cashier-card__btn-ready" onClick={() => {deleteOrder()}}>
                        {t('cashier_card.done')}
                    </div>
                )
            }

        </div>
    );
}

export default CashierCard;
