import React from 'react';
import changeOrderNum from "../../../helpers/changeOrderNum";
import {useTranslation} from "react-i18next";

function AppetitoTemplate({orders, ordersDone, in_progress_column, ready_column, font_size, more, moreDone, maxOrdersInBoard}) {
    const { t } = useTranslation();
    return (
        <div className="templates">
            <div className="templates__appetito">
                <div className="templates__appetito__block" >
                    <div className="templates__appetito__block__head">
                       <div className="templates__appetito__block__head__title">
                           <span>{in_progress_column}</span>
                       </div>
                        <div className="templates__appetito__block__head__title">
                            <span>{ready_column}</span>
                        </div>
                    </div>
                    <div className="templates__appetito__block__main">
                        <div className="templates__appetito__block__main__numbers" id="numbers">
                            {
                                orders.map((order, index) => {
                                    return <div style={{
                                        fontSize: `${(font_size / 1280) * 90}vw`,
                                        lineHeight: `${(font_size / 1280) * 98}vw`,
                                        width: `calc(${((font_size * 2) / 1280) * 100}vw + .21875vw)`
                                    }} className="templates__appetito__block__main__numbers__number"
                                                key={order}>{changeOrderNum(order)}</div>
                                })
                            }

                            {Array.from({ length: maxOrdersInBoard - orders.length }).map((_, index) => (
                                <div
                                    key={`empty_${index}`}
                                    style={{
                                    fontSize: `${(font_size / 1280) * 90}vw`,
                                    lineHeight: `${(font_size / 1280) * 98}vw`,
                                    width: `calc(${((font_size * 2) / 1280) * 100}vw + .21875vw)`
                                }} className="templates__appetito__block__main__numbers__number hide_block">
                                    1
                                </div>
                            ))}

                            {
                                more > 0 ? (
                                    <div className="templates__more templates__appetito__more">
                                        <span>{t('cabinet.templates_more_numbers', {more})}</span>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="templates__appetito__block__main__numbers">
                            {
                                ordersDone.map((order, index) => {
                                    return <div style={{
                                        fontSize: `${(font_size / 1280) * 90}vw`,
                                        lineHeight: `${(font_size / 1280) * 98}vw`,
                                        width: `calc(${((font_size * 2) / 1280) * 100}vw + 0.78125vw)`}} className="templates__appetito__block__main__numbers__number templates__appetito__block__main__numbers__number-done" key={order}>{changeOrderNum(order)}</div>
                                })
                            }
                            {Array.from({ length: maxOrdersInBoard - ordersDone.length }).map((_, index) => (
                                <div
                                    key={`empty_${index}`}
                                    style={{
                                        fontSize: `${(font_size / 1280) * 90}vw`,
                                        lineHeight: `${(font_size / 1280) * 98}vw`,
                                        width: `calc(${((font_size * 2) / 1280) * 100}vw + .21875vw)`
                                    }} className="templates__appetito__block__main__numbers__number hide_block">
                                    1
                                </div>
                            ))}
                            {
                                moreDone > 0 ? (
                                    <div className="templates__more">
                                        <span>{t('cabinet.templates_more_numbers', {more: moreDone})}</span>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppetitoTemplate;
